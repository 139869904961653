
import { Component, Vue } from "vue-property-decorator";

type Credentials = {
  login: null | string;
  password: null | string;
  remember?: boolean;
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    LoginForm: () => import("@/app/modules/auth/components/LoginForm.vue")
  }
})
export default class LoginPage extends Vue {
  credentials: Credentials = {
    login: localStorage.getItem("login"),
    password: localStorage.getItem("password"),
    remember:
      !!localStorage.getItem("login") && !!localStorage.getItem("password")
  };

  errors = null;

  get errorMessage() {
    return this.$store.getters["app/errorMessage"];
  }

  getToken() {
    this.$store.commit("app/setErrorMessage", null);

    this.$store
      .dispatch("auth/login", this.credentials)
      .then(({ data }) => {
        this.rememberCredentials();

        this.$store.commit("auth/setApiToken", data.api_token);
        this.$router.push({ name: "tasks" });
      })
      .catch(error => {
        this.errors = error.response.data.errors;
      });
  }

  rememberCredentials() {
    if (this.credentials.remember)
      this.$store.commit("auth/saveCredentials", this.credentials);
    else this.$store.commit("auth/destroyCredentials");
  }
}
